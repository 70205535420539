import styled from 'styled-components';

export const ProjectsPageWrapper = styled.div`
    width: 100vw;
    /* height: calc(100vh - 79px); */
    /* height: 130vh; */
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    /* background-color: green; */
`